<template>
  <div>
    <b-row>
      <b-col>
        <label>Grupo</label>
        <b-input
          id="group"
          type="text"
          value=""
          v-model="model.description"
          autocomplete="new-password"
          name="group"
        ></b-input>
      </b-col>
      <b-col>
        <label>Unidade</label>
        <b-form-select
          v-model="model.unity_id"
          :options="unitiesSelectOptions"
        ></b-form-select>
      </b-col>
      <b-col v-if="editing" md="3">
        <label>Status</label>
        <b-form-select
          v-model="model.active"
          :options="[
            { text: 'Ativo', value: '1' },
            { text: 'Inativo', value: '0' },
          ]"
        ></b-form-select>
      </b-col>
    </b-row>
    <hr />
    <div class="d-flex justify-content-end">
      <b-button v-if="editing" @click="update_group" variant="primary-custom"
        >Alterar</b-button
      >
      <b-button v-else @click="save_group" variant="primary-custom"
        >Salvar</b-button
      >
    </div>   
  </div>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
      editing: false,
      model: {
        active: "1",
        description: "",
        unity_id:"",
      },
    };
  },  
  computed: {
    unitiesSelectOptions() {
      return _.map(this.$store.state.groups.unities, (unity) => {
        return { value: unity.id, text: unity.company_name };
      });
    },
  },
  methods: {
    async save_group() {      
      const response = await this.$store.dispatch(
        "groups/save_group",
        this.model
      );
      if (response) {
        this.$router.push("/grupos");
      }
    },
    async update_group() {
      const response = await this.$store.dispatch(
        "groups/update_group",
        this.model
      );
      if (response) {
        this.$router.push("/grupos");
      }
    },
  },
  async mounted() {
    this.$store.dispatch("groups/groups_add_edit_init");
    const groupId = this.$route.params.groupId;

    if (groupId) {
      console.log("editando");
      this.editing = true;
      this.model = await this.$store.dispatch(
        "groups/get_group_by_id",
        groupId
      );
    }   
    
    
  },
};
</script>

<style>
</style>